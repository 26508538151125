<template>
  <div id="comorbidity" class="form-horizontal">
    <div class="title-bar">
      <div>
      <div class="pull-right center-float" v-if="!formActive"><Button id="comorbidityEditBtn" @click="editDetails" :label="$t(`edit_details`)"></Button></div>
      <div class="pull-right center-float" v-if="formActive"><Button id="comorbidityCancelBtn" color="secondary" @click="cancel" :label="$t(`cancel`)"></Button></div>
      </div>
    </div>

    <Form :key="reload" :name="'Comorbidity'" :isEditing="formActive" :loadFormData="true" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?episodeId=' + patientId" />

  </div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import Button from '../../../../shared/components/Button.vue'
import EventBus from '@/app/shared/event-bus.js'
import { mapState } from 'vuex'

export default {
  name: 'Comorbidity',
  data: self => ({
    formActive: false,
    reload: 0
  }),
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  destroyed () {
    EventBus.$off('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  components: {
    Form,
    Button
  },
  computed: {
    ...mapState('Form', ['isLoading']),
    ...mapState('Patient', ['patientId'])
  },
  methods: {
    editDetails () {
      if (this.isLoading) {
        return
      }
      this.formActive = true
    },
    cancel () {
      if (this.isLoading) {
        return
      }
      this.formActive = false
      this.reload += 1
    },
    back () {
      if (!this.isLoading) {
        this.formActive = false
      }
    },
    formSavedSuccessfully () {
      this.back()
    }
  }
}
</script>

<style scoped>
.form-horizontal {
  box-sizing: unset !important;
  background-color: var(--background-color-primary-light-dark);
  padding: 20px 0 10px 0;
}
.center-float {
  margin-right: 20px;
}
.pull-right {
  justify-content: flex-end;
  display: flex;
}
.text-no-margin {
  margin: auto;
}
</style>
